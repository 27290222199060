import React, { useState } from "react"
import { FaTimes } from "react-icons/fa";
import { SiUbereats, SiDeliveroo } from "react-icons/si";
import Modal from 'react-modal';


export default function Shortcut({ data, size }) {
  const iconSize = size === 'small' ? "0.8em" : "2em"
  return (
    <div className="shortcut flex flex-row items-center justify-center space-x-4">

      {data.restaurant.settings?.delivery && (
        <a href={`https://order.we-eats.com/${data.restaurant.urlName}`} target="_blank" rel="noreferrer" className="rounded-full h-20 w-20 flex flex-col items-center justify-center bg-white-900 bg-white hover:bg-gray-200  ring-1 ring-gray-800">
          <div className="rounded-full h-8 w-8"><img src="/assets/delivery.png" /></div>
          <div className="text-black">Livraison</div>
        </a>
      )}

      {data.restaurant.settings?.takeaway && (
        <a href={`https://order.we-eats.com/${data.restaurant.urlName}`} target="_blank" rel="noreferrer" className="rounded-full h-20 w-20 flex flex-col items-center justify-center bg-white-900 bg-white hover:bg-gray-200  ring-1 ring-gray-800">
          <div className="rounded-full h-8 w-8"><img src="/assets/take-away.png" /></div>
          <div className="text-black">Click&Collect</div>
        </a>
      )}

      {data.restaurant.websiteSettings?.uberEatsUrl && (
        <a href={`${data.restaurant.websiteSettings?.uberEatsUrl}`} target="_blank" rel="noreferrer" className="rounded-full h-20 w-20 flex flex-col items-center justify-center bg-white-900 bg-white hover:bg-gray-200  ring-1 ring-gray-800">
          <div className="rounded-full h-8 w-8"><SiUbereats size={iconSize} color="black" /></div>
          <div className="text-black">Livraison</div>
        </a>
      )}

      {data.restaurant.websiteSettings?.deliverooUrl && (
        <a href={`${data.restaurant.websiteSettings?.deliverooUrl}`} target="_blank" rel="noreferrer" className="rounded-full h-20 w-20 flex flex-col items-center justify-center bg-white-900 bg-white hover:bg-gray-200  ring-1 ring-gray-800">
          <div className="rounded-full h-8 w-8"><SiDeliveroo size={iconSize} color="black" /></div>
          <div className="text-black">Livraison</div>
        </a>
      )}

      {data.restaurant.websiteSettings?.googleMapsUrl && (!data.restaurant.websiteSettings?.uberEatsUrl && !data.restaurant.websiteSettings?.deliverooUrl) && (
        <a href={`${data.restaurant.websiteSettings?.googleMapsUrl}`} target="_blank" rel="noreferrer" className="rounded-full h-20 w-20 flex flex-col items-center justify-center bg-white-900 bg-white hover:bg-gray-200  ring-1 ring-gray-800">
          <div className="rounded-full h-8 w-8"><img src="/assets/location.png" /></div>
          <div className="text-black">Itinéraire</div>
        </a>
      )}

      <a href={`tel:${data.restaurant.informations.phone}`} className="rounded-full h-20 w-20 flex flex-col items-center justify-center bg-white-900 bg-white hover:bg-gray-200  ring-1 ring-gray-800">
        <div className="rounded-full h-8 w-8"><img src="/assets/call.png" /></div>
        <div className="text-black">Appeler</div>
      </a>

    </div>
  )
}
